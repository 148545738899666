import pino from 'pino';

// Playwright is not playing nice with anything in here; so we need to check for the existence of
// import.meta.env.DEV before using it.
const isDevServer =
	'env' in import.meta && typeof import.meta.env === 'object' && import.meta.env.DEV === true;

/**
 * Pino logger
 *
 * Note: In use, you need to pass objects/arrays/etc. as the _first_ argument to the logger method.
 * @see https://github.com/pinojs/pino/issues/281
 *
 * @example
 *
 * ```ts
 * logger.info({ some: 'object' }, 'Some message');
 * ```
 */
export const logger = pino({
	browser: {
		disabled: !isDevServer
	},
	level: isDevServer ? 'trace' : 'info',
	timestamp: true,
	transport: isDevServer
		? {
				options: {
					colorize: true
				},
				target: 'pino-pretty'
			}
		: undefined
});
